.waves-flip {
  perspective: 1000px;

  .waves {
    transform: rotateX(180deg);
    bottom: -80px;
  }

  .wave {
    bottom: -50px;
    transform: translateY(0px);
    background-image: url("../img/wave_.svg");

    @media (max-width: 1500px) {
      background-size: cover !important;
    }

    &__two {
      transform: translateY(0px);
      background-image: url("../img/wave2.svg");
    }
    &__three {
      transform: translateY(0px);
      background-image: url("../img/wave3.svg");
    }
  }
}

.waves {
  width: 100%;
  background: #fff;
  position: absolute;
  height: 500px;
  bottom: 0;
}

.wave {
  background-image: url("../img/wave.svg");
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: contain;
  height: 625px;
  width: 100%;
  position: absolute;
  bottom: -50px;
  z-index: 3;
  transform: translateY(0px);

  @include animate(waves, 90s, infinite, linear);

  @include lg {
    background-size: cover;
  }

  @include md {
    width: 1600px;
  }

  &__two {
    background-image: url("../img/wave2.svg");
    z-index: 2;
    opacity: 0.7;
    transform: translateY(0px);

    @include animate(waves__two, 90s, infinite, linear);

  }

  &__three {
    background-image: url("../img/wave3.svg");
    z-index: 1;
    transform: translateY(0px);

    @include animate(waves__three, 180s, infinite, linear);
  }
}


@include keyframes(waves) {
  0% { background-position: 0 100%;}
  100% { background-position: -1600px 100%;}
}

@include keyframes(waves__two) {
  from { background-position: -800px 100%; }
  to { background-position: 1600px 100%; }
}

@include keyframes(waves__three) {
  from { background-position: 800px 100%; }
  to { background-position: -1600px 100%; }
}
