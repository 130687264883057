.header-section {
  width: 100%;
  display: block;
  background: url("../img/header-bg.jpg");
  background-size: cover;
  background-position: center center;
  height: 620px;
  position: relative;
  overflow: hidden;

  body.single & {
    height: 490px;

    @include md {
      height: 400px;
    }

    @include sm {
      height: auto;
    }

  }

  @include sm {
    height: auto;
    margin-bottom: 40px;
  }

  &--contact {
    height: 720px;

    @include sm {
      height: 480px;
    }
  }

  &::before {
    content: "";
    position: absolute;
    z-index: 2;
    bottom: -2px;
    width: 100%;
    height: 170px;
    background-image: url("../img/header-bg-overlap.png");
    background-size: 100% 170px;
    background-repeat: no-repeat;

    @include sm {
      background-size: contain;
      bottom: -5%;
      padding: 7%;
      height: auto;
    }
  }

  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 120px;
    width: 100%;
    height: 436px;
    background: url("../img/header-circles.png");
    background-repeat: repeat-x;
    background-position: center center;

    @include sm {
      display: none;
    }
  }
}

.header {
  @include container;
  padding-top: 24rem;

  body.single & {
    padding-top: 20rem;

    @include md {
      padding-top: 10rem;
    }

    @include sm {
    padding-top: 5rem;
    }
  }

  @include sm {
    padding-top: 10rem;
    padding-bottom: 0;
  }

  &__text {
    color: #fff !important;
    position: relative;
    z-index: 3;

    @include sm {
      padding-top: 40px;
      padding-bottom: 80px;
    }
  }

  &__headline {
    margin: 0;
    padding: 0;
    font-size: 2rem;
    font-weight: 400;
    line-height: 1;
    position: relative;
    padding-bottom: 2.5rem;
    margin-bottom: 2.5rem;
    color: #fff !important;

    &::after {
      content: "";
      display: block;
      height: 0.2rem;
      width: 13rem;
      background: $purple;
      bottom: 0;
      position: absolute;
    }

    &--title {
      font-size: 3rem;
      font-weight: 700;

    }
  }

  &__paragraph {
    font-size: 3rem;
    line-height: 1.2;
    font-weight: bold;

    @include sm {
      font-size:2rem;
    }

    br {
      @include sm {
        display: none;
      }
    }
  }
}


#breadcrumbs {
  a {
    color: #fff !important;
  }
}

body.single .body-cont {
  z-index: 2;
  margin-top: -61px;
  position: relative;
}
