// @import "vendor/bootstrap";
// @import "vendor/owl-carousel";
@import "vendor/hamburgers";

@import "fonts";

@import "variables";
@import "mixins";
@import "typography";
@import "utilities";

@import "components/nav";
@import "components/dropdown";
@import "components/email-capture";
@import "components/footer";
@import "components/global-header";
// @import "components/card";
// @import "components/form";
@import "components/bubbles";
@import "components/waves";
// @import "components/products";




.page_content {
    padding: 20px 0 !important;
}

.post_content p {
  margin-bottom: 2em;
}

.post_content .wp-block-quote p {
  margin-bottom: 0;
}

.post_content .wp-block-quote {
  margin-bottom: 2em;
}
