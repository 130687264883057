.footer {
  @include container;
  display: flex;
  justify-content: space-between;
  border-top: 3px solid #d1dae3;
  padding-top: 8rem;
  padding-bottom: 8rem;
  flex-wrap: wrap;
  font-size: 1.6rem;

  &__left {
    width: 57rem;
    display: flex;

    @include md {
      width: 36rem;
    }

    @include sm {
      width: 100%;
      padding-bottom: 4rem;
    }
  }

  &__logo {
    flex: 0 0 96px;

    img {
      display: block;
    }
  }

  &__text p{
    margin: 0;

    @include sm {
      font-size: 1.4rem;
    }
  }

  &__menu {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-link{
      color: $body;
      font-weight: bold;
      display: block;
      padding: 0 1.8rem;
      position: relative;
      transition: ease all 0.2s;

      @include sm {
        padding: 0;
        padding-right: 3rem;
      }

      &:hover {
        color: $teal;
        text-decoration: none;
      }
    }
  }
}
