html {
  font-size: 62.5%;
}

body {
  font-family: $font-stack;
  font-size: 1.8rem;
  color: $body;
}

p {
  line-height: 1.5;
  margin-bottom: 2.5rem;
}

p strong {
  font-size: 2.2rem;
  font-weight: 700;
  color: #000;
}

h2 {
  margin: 0;
  padding: 0;
  color: $teal;
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.2;
  position: relative;
  padding-bottom: 2.8rem;
  margin-bottom: 2.8rem;
  letter-spacing: -0.05em;

  &::after {
    content: "";
    display: block;
    height: 0.2rem;
    width: 10rem;
    background: $purple;
    bottom: 0;
    position: absolute;
  }
}

h3 {
  margin: 0;
  padding: 0;
  color: $black;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: -.05em;
  margin-bottom: 1.2rem;
  position: relative;
}

.button {
  display: inline-block;
  letter-spacing: -0.04em;
  font-weight: bold;
  color: $teal;
  border: 1px solid $teal;
  border-radius: $border-radius;
  padding: 0.5em 1em;
  transition: all ease-in-out 0.3s;
  position: relative;
  transform: none;
  text-align: center;

  @include sm {
    width: 100%;
  }

  &:hover {
    background: $teal;
    color: #fff;
    text-decoration: none;
    transform: translateY(-5px);
  }
}

::selection {
  background: $teal;
  color: #fff;
}
